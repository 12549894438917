import type { Action } from '@cocoonspace/types/admin/describe'
import type { AxiosInstance } from 'axios'

export interface CustomAction {
	config: {
		uriParams: {
			describeId: string
			entryId: string
			action: Action['type'] | string
		}
		params?: Record<string, any>
	}
	response: {
		data: any
	}
}

export const customActionModuleEntry =
	(axiosInstance: AxiosInstance) =>
	({ uriParams, params }: CustomAction['config']) =>
		axiosInstance.post<CustomAction['response']>(
			`/_admin/${uriParams.describeId}/${uriParams.entryId}/${uriParams.action}`,
			undefined,
			{ params },
		)
