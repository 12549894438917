import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface SimulateEvent {
	uriParams: Api.Operations['simulateEvent']['parameters']['path']
	body: NonNullable<
		Api.Operations['simulateEvent']['requestBody']
	>['content']['application/json']
	response: Api.Operations['simulateEvent']['responses']['200']['content']['application/json']
}

export const simulateEvent = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<SimulateEvent>(
		{
			method: 'post',
			url: '/events/{eventId}/simulate',
			...overideConfig,
		},
		axiosInstance,
	)
