type NonNullProperties<T> = Pick<
	T,
	{
		[Prop in keyof T]: T[Prop] extends undefined ? never : Prop
	}[keyof T]
>

export const getCleanObject = <T extends Record<string, any>>(obj: T) => {
	const entries = Object.entries(obj).filter(
		([_, value]) => value !== undefined && value !== null && value !== '',
	)

	return Object.fromEntries(entries) as NonNullProperties<T>
}
