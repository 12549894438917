import { useFirebaseApp } from '@cocoonspace/shared/domains/auth/providers/firebase-app.provider'
import axios, { type AxiosError, type AxiosRequestConfig } from 'axios'
import { getAuth } from 'firebase/auth'
import { i18n } from 'next-i18next'
import { stringify } from 'qs'
import { useRef } from 'react'
import { toast } from 'sonner'
import { createModuleEntry } from './endpoints/create-module-entry'
import { customActionModuleEntry } from './endpoints/custom-action-module-entry'
import { deleteModuleEntry } from './endpoints/delete-module-entry'
import { getModuleDescribe } from './endpoints/get-module-describe'
import { getModuleEntry } from './endpoints/get-module-entry'
import { listModuleEntries } from './endpoints/list-module-entries'
import { listModules } from './endpoints/list-modules'
import { replaceFile } from './endpoints/replace-file'
import { updateModuleEntry } from './endpoints/update-module-entry'
import { uploadFile } from './endpoints/upload-file'
import { authTokenInterceptor } from './interceptors/request/auth-token'

export interface SdkConfig {
	defaultAxiosOptions?: AxiosRequestConfig
	getAuthToken?: (config: AxiosRequestConfig) => Promise<string>
}

const createSdk = (sdkConfig: SdkConfig) => {
	const axiosInstance = axios.create({
		baseURL: `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1`,
		paramsSerializer: (params) =>
			stringify(params, { arrayFormat: 'indices', allowDots: true }),
		...sdkConfig.defaultAxiosOptions,
	})

	// @ts-ignore
	axiosInstance.interceptors.request.use(authTokenInterceptor(sdkConfig))

	axiosInstance.interceptors.response.use(undefined, (err: AxiosError) => {
		if (err.response?.status === 401) {
			document.location.href = '/login'
		}

		return Promise.reject(err)
	})

	axiosInstance.interceptors.response.use(undefined, (err: AxiosError<any>) => {
		toast.error(
			i18n?.t([
				`errors:api.${err.response?.data?.errors[0].code}`,
				err.response?.data?.errors[0].title,
			]),
			{
				description: err.response?.headers?.['request-id']
					? `Request Id: ${err.response?.headers?.['request-id']}`
					: '',
			},
		)

		return Promise.reject(err)
	})

	return {
		axiosInstance,
		listModules: listModules(axiosInstance),
		getModuleDescribe: getModuleDescribe(axiosInstance),
		listModuleEntries: listModuleEntries(axiosInstance),
		createModuleEntry: createModuleEntry(axiosInstance),
		customActionModuleEntry: customActionModuleEntry(axiosInstance),
		updateModuleEntry: updateModuleEntry(axiosInstance),
		createUpdateModuleEntry: updateModuleEntry(axiosInstance),
		getModuleEntry: getModuleEntry(axiosInstance),
		deleteModuleEntry: deleteModuleEntry(axiosInstance),
		uploadFile: uploadFile(axiosInstance),
		replaceFile: replaceFile(axiosInstance),
	}
}

export const useAdminApi = (): ReturnType<typeof createSdk> => {
	const { firebaseApp } = useFirebaseApp()

	return useRef(
		createSdk({
			getAuthToken: () => {
				if (typeof window === 'object') {
					return (
						getAuth(firebaseApp).currentUser?.getIdToken() ||
						Promise.resolve('')
					)
				}

				return Promise.resolve('')
			},
		}),
	).current
}
