import type { AxiosInstance } from 'axios'
import { getPrices } from '../endpoints/get-prices'
import { getSpace } from './../endpoints/get-space'
import { getSpaceAvailability } from './../endpoints/get-space-availability'
import { getSpaceCalendar } from './../endpoints/get-space-calendar'
import { listSpaceAvailabilities } from './../endpoints/list-space-availabilities'
import { listSpaces } from './../endpoints/list-spaces'

export const spacesModule = (axiosInstance: AxiosInstance) => ({
	getAll: listSpaces(axiosInstance),
	getOne: getSpace(axiosInstance),
	getAvailability: getSpaceAvailability(axiosInstance),
	listAllAvailabilities: listSpaceAvailabilities(axiosInstance),
	getCalendar: getSpaceCalendar(axiosInstance),
	getPrices: getPrices(axiosInstance),
})
