import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { Pie, PieChart } from 'recharts'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import {
	type ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from '~/components/ui/chart'
import { Skeleton } from '~/components/ui/skeleton'
import { useListEntityEntries } from '~/lib/admin-sdk-js/domains/entries/entries'
import { PercentIncrease } from './percent-increase'

const chartConfig = {
	meeting: {
		label: 'Meeting',
		color: 'var(--type-event)',
	},
	plateform: {
		label: 'Plateforme',
		color: 'var(--type-presta)',
	},
	visit: {
		label: 'Visite',
		color: 'var(--type-visit)',
	},
	quotation: {
		label: 'Devis',
		color: 'var(--type-quotation)',
	},
	desk: {
		label: 'Desk',
		color: 'var(--type-desk)',
	},
} satisfies ChartConfig

const DayTotal = ({
	count,
	totalDayPrice,
	isLoading,
}: {
	count: number
	totalDayPrice: number
	isLoading: boolean
}) => {
	const { t } = useTranslation()

	return (
		<div className='relative z-30 flex min-w-[152px] flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-t-0 sm:border-l'>
			<span className='text-muted-foreground text-xs'>Total aujourd'hui</span>

			<span className='flex items-end gap-2 font-bold text-lg leading-none sm:text-3xl'>
				{isLoading ? (
					<Skeleton className='h-6 w-full' />
				) : (
					<>
						{count || 0}

						<div className='flex flex-row items-end gap-1 text-slate-500 text-sm leading-none'>
							{t('common:currency.full', {
								val: totalDayPrice,
							}).replace(',00', '')}

							<span className='text-[8px] leading-none'>HT</span>
						</div>
					</>
				)}
			</span>
		</div>
	)
}

const TotalMonth = ({
	count,
	prevCount,
	isLoading,
}: {
	count: number
	prevCount: number
	isLoading: boolean
}) => {
	return (
		<div className='relative z-30 flex min-w-[152px] flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-t-0 sm:border-l'>
			<span className='text-muted-foreground text-xs'>Total ce mois-ci</span>

			<span className='flex flex-row items-end gap-1 font-bold text-lg leading-none sm:text-3xl'>
				{isLoading ? (
					<Skeleton className='h-6 w-full' />
				) : (
					<>
						{count || 0}

						<PercentIncrease
							prev={prevCount}
							current={count}
						/>
					</>
				)}
			</span>
		</div>
	)
}

export const EventsRecapStats = () => {
	const { t } = useTranslation()

	const events = useListEntityEntries('events', { page_size: 1000 })

	const monthStart = dateTimeUtils().set('date', 1).customFormat('dateTech')
	const monthEnd = dateTimeUtils().endOf('month').customFormat('dateTech')

	const monthParams = {
		page_size: 1,
		'day.start': monthStart,
		'day.end': monthEnd,
	}

	const eventsThisMonth = useListEntityEntries('events', monthParams)

	const meetingEventsThisMonth = useListEntityEntries('events', {
		...monthParams,
		// @ts-expect-error
		type: 'meeting',
	})

	const visitsEventsThisMonth = useListEntityEntries('events', {
		...monthParams,
		// @ts-expect-error
		type: 'visit',
	})

	const quotationsEventsThisMonth = useListEntityEntries('events', {
		...monthParams,
		// @ts-expect-error
		type: 'quotation',
	})

	const deskEventsThisMonth = useListEntityEntries('events', {
		...monthParams,
		// @ts-expect-error
		type: 'desk',
	})

	const eventsPrevMonth = useListEntityEntries('events', {
		page_size: 1,
		// @ts-expect-error
		'day.start': dateTimeUtils()
			.set('date', 1)
			.set('month', -1)
			.customFormat('dateTech'),
		'day.end': dateTimeUtils()
			.set('month', -1)
			.endOf('month')
			.customFormat('dateTech'),
	})

	const totalDayPrice = useMemo(() => {
		return (
			events.data?.data?.reduce(
				(acc, event) => acc + (event.total_price || 0),
				0,
			) || 0
		)
	}, [events])

	const chartData = useMemo(() => {
		const visits = visitsEventsThisMonth.data?.meta?.count || 0
		const meetings = meetingEventsThisMonth.data?.meta?.count || 0
		const desks = deskEventsThisMonth.data?.meta?.count || 0
		const quotations = quotationsEventsThisMonth.data?.meta?.count || 0

		return [
			{ name: 'meeting', value: meetings },
			{ name: 'quotation', value: quotations },
			{ name: 'visit', value: visits },
			{ name: 'desk', value: desks },
		].map((item) => ({
			...item,
			fill: chartConfig[item.name as keyof typeof chartConfig]?.color,
		}))
	}, [
		meetingEventsThisMonth,
		quotationsEventsThisMonth,
		visitsEventsThisMonth,
		deskEventsThisMonth,
	])

	return (
		<Card>
			<CardHeader className='flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row'>
				<div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
					<CardTitle>Évènements</CardTitle>

					<CardDescription>
						Évènements du mois par type, total de la journée et total du mois
						(comparé au mois précédent).
					</CardDescription>
				</div>

				<div className='flex'>
					<DayTotal
						count={events.data?.meta?.count || 0}
						totalDayPrice={totalDayPrice / 100}
						isLoading={events.isLoading}
					/>

					<TotalMonth
						count={eventsThisMonth.data?.meta?.count || 0}
						prevCount={eventsPrevMonth.data?.meta?.count || 0}
						isLoading={eventsThisMonth.isLoading}
					/>
				</div>
			</CardHeader>

			<CardContent className='flex flex-row gap-4 px-2 sm:p-6'>
				{eventsThisMonth.isLoading ? (
					<Skeleton className='h-[250px] w-full' />
				) : (
					<ChartContainer
						config={chartConfig}
						className='aspect-auto h-[250px] w-full'
					>
						<PieChart
							width={400}
							height={400}
						>
							<Pie
								nameKey='name'
								dataKey='value'
								isAnimationActive={false}
								data={chartData}
								cx='50%'
								cy='50%'
								outerRadius={80}
								labelLine
								label
							/>

							<ChartTooltip
								content={
									<ChartTooltipContent className='w-[150px] capitalize' />
								}
							/>

							<ChartLegend
								content={<ChartLegendContent className='capitalize' />}
							/>
						</PieChart>
					</ChartContainer>
				)}
			</CardContent>
		</Card>
	)
}
