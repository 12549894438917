import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface DeleteAccountPaymentMethod {
	uriParams: Api.Operations['deleteAccountPaymentMethod']['parameters']['path']
	response: Api.Operations['deleteAccountPaymentMethod']['responses']['204']
}

export const deleteAccountPaymentMethod = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<DeleteAccountPaymentMethod>(
		{
			method: 'delete',
			url: '/account/payment_methods/{methodId}',
			...overideConfig,
		},
		axiosInstance,
	)
