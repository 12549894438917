import type { AxiosInstance } from 'axios'
import { checkBookingPayment } from '../endpoints/check-booking-payment'
import { createBooking } from './../endpoints/create-booking'
import { getBooking } from './../endpoints/get-booking'
import { listBookings } from './../endpoints/list-bookings'
import { updateBooking } from './../endpoints/update-booking'
import { validateBooking } from './../endpoints/validate-booking'

export const bookingModule = (axiosInstance: AxiosInstance) => ({
	createOne: createBooking(axiosInstance),
	getAll: listBookings(axiosInstance),
	getOne: getBooking(axiosInstance),
	updateOne: updateBooking(axiosInstance),
	validate: validateBooking(axiosInstance),
	checkBookingPayment: checkBookingPayment(axiosInstance),
})
