import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface UpdateMe {
	body: NonNullable<
		Api.Operations['updateMe']['requestBody']
	>['content']['application/json']
	response: Api.Operations['updateMe']['responses']['200']['content']['application/json']
}

export const updateMe = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<UpdateMe>(
		{
			method: 'patch',
			url: '/me',
			...overideConfig,
		},
		axiosInstance,
	)
