import type { ComponentProps } from 'react'
import { cn } from '~/lib/utils'

export const DescribeLayoutBody = ({
	className,
	...props
}: ComponentProps<'div'>) => (
	<div
		{...props}
		className={cn(
			'flex flex-1 flex-col gap-4 overflow-auto rounded-lg bg-card p-4',
			className,
		)}
	/>
)
