import { ChevronsUpDownIcon, Trash2Icon } from 'lucide-react'
import { useMemo } from 'react'
import type { EntityName } from '~/config/entities'
import { useListEntityEntries } from '~/lib/admin-sdk-js/domains/entries/entries'
import { cn } from '~/lib/utils'
import { Badge } from '../ui/badge'
import { Button } from '../ui/button'
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { Label } from '../ui/label'

export const EntitySelectMultiFilter = ({
	label,
	name,
	value,
	entity,
	filters,
	onChange,
}: {
	label: string
	name: string
	value: string[]
	entity: EntityName
	filters?: Record<string, string>
	onChange: (value: string[] | null) => void
}) => {
	const { data: rows } = useListEntityEntries(entity, filters)

	const entitiesByIds = useMemo(() => {
		return rows?.data?.reduce((acc, option) => {
			acc[option.id] = option
			return acc
		}, {})
	}, [rows])

	return (
		<div className='flex flex-col gap-1'>
			{!!label && <Label htmlFor={name}>{label}</Label>}

			<DropdownMenu>
				<div className='relative'>
					<DropdownMenuTrigger className='flex min-w-[250px] max-w-[300px] flex-row items-center gap-2'>
						<Button
							variant='outline'
							role='combobox'
							className={cn('w-full justify-between gap-1', {
								'pr-10': !!value?.length,
							})}
						>
							<div className='flex flex-1 flex-row gap-2 truncate'>
								{value?.map((item) => (
									<Badge key={item}>
										{entitiesByIds?.[item]?.title || item}
									</Badge>
								))}
							</div>

							{!value?.length && (
								<ChevronsUpDownIcon className='ml-auto size-4 w-3 opacity-50' />
							)}
						</Button>
					</DropdownMenuTrigger>

					{!!value?.length && (
						<button
							type='button'
							className='absolute top-px right-4 bottom-px bg-backround'
							onClick={() => onChange(null)}
						>
							<Trash2Icon className='size-4' />
						</button>
					)}
				</div>

				<DropdownMenuContent className='max-h-[300px] overflow-y-auto'>
					{rows?.data?.map((option) => (
						<DropdownMenuCheckboxItem
							key={option.id}
							checked={value?.includes(option.id)}
							onCheckedChange={() =>
								onChange(
									value?.includes(option.id)
										? value.filter((id) => id !== option.id)
										: [...(value || []), option.id],
								)
							}
							className='gap-2'
						>
							{option.title || option.id || ''}
						</DropdownMenuCheckboxItem>
					))}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	)
}
