import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface GetInvoice {
	uriParams: Api.Operations['getInvoice']['parameters']['path']
	response: Api.Operations['getInvoice']['responses']['200']['content']['application/json']
}

export const getInvoice = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetInvoice>(
		{
			method: 'get',
			url: '/invoices/{invoiceRef}/infos',
			...overideConfig,
		},
		axiosInstance,
	)
