import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface GetSpaceAvailability {
	uriParams: Api.Operations['getSpaceAvailability']['parameters']['path']
	params?: Api.Operations['getSpaceAvailability']['parameters']['query']
	response: Api.Operations['getSpaceAvailability']['responses']['200']['content']['application/json']
}

export const getSpaceAvailability = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetSpaceAvailability>(
		{
			method: 'get',
			url: '/spaces/{spaceId}/availability',
			...overideConfig,
		},
		axiosInstance,
	)
