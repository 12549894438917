import type { AxiosInstance } from 'axios'

interface ListModuleEntries {
	config: {
		uriParams: {
			describeId: string
		}
		params?: {
			page?: number
			page_size?: number
			[filter: string]: unknown
		}
	}
	response: {
		data: any[]
		meta: {
			count: number
			page: number
			page_size: number
		}
	}
}

export const listModuleEntries =
	(axiosInstance: AxiosInstance) =>
	({ uriParams, ...config }: ListModuleEntries['config']) =>
		axiosInstance.get<ListModuleEntries['response']>(
			`/_admin/${uriParams.describeId}`,
			config,
		)
