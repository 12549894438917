export type CocoonFeature = {
	id: string
	category: 'equipment' | 'services'
	isSearchFilter?: boolean
}

export const cocoonFeatures: CocoonFeature[] = [
	{ id: 'tv', category: 'equipment', isSearchFilter: true },
	{ id: 'video_projector', category: 'equipment', isSearchFilter: true },
	{ id: 'air_conditioning', category: 'equipment', isSearchFilter: true },
	{ id: 'paperboard', category: 'equipment' },
	{ id: 'whiteboard', category: 'equipment' },
	{ id: 'hdmi_adapter', category: 'equipment' },
	{ id: 'sound_system', category: 'equipment' },
	{ id: 'coffee', category: 'services', isSearchFilter: true },
	{ id: 'water', category: 'services', isSearchFilter: true },
	{ id: 'wifi', category: 'services' },
	{ id: 'restroom', category: 'services' },
	{ id: 'disabled_access', category: 'services' },
	{ id: 'elevator', category: 'services', isSearchFilter: true },
]
