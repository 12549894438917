import type { UiFieldDescription } from '~/hooks/admin-api/use-describe.hook'

export const getAttributeColSize = (attribute: UiFieldDescription) => {
	const size: Record<string, number> = {
		amount: attribute.cols || 3,
		boolean: attribute.cols || 3,
		date: attribute.cols || 3,
		float: attribute.cols || 3,
		gallery: attribute.cols || 12,
		hours: attribute.cols || 12,
		int: attribute.cols || 3,
		link: attribute.cols || 12,
		list: attribute.cols || 12,
		longstring: attribute.cols || 12,
		multi: attribute.cols || 12,
		select: attribute.cols || 3,
		simplelist: attribute.cols || 12,
		string: attribute.cols || 3,
		tag: attribute.cols || 12,
		time: attribute.cols || 3,
	}

	return size[attribute.type] ? size[attribute.type] : 3
}
