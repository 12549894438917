import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { Button } from '~/components/ui/button'
import { Calendar } from '~/components/ui/calendar'
import { Form } from '~/components/ui/form'
import { FormControlledInput } from '~/components/ui/form-controlled-input'
import EditorTime from '~/pages/[describeId]/_components/atoms/attribute-editor/editor-time'
import type { DateTimeSlot } from '../../../_types/date-time-slot'

type FormValues = z.infer<typeof formValuesSchema>

const formValuesSchema = z
	.object({
		days: z.array(z.string()).min(1),
		start: z.number(),
		end: z.number(),
	})
	.refine((data) => data.end > data.start, {
		message: 'errors:forms.endTimeMustBeHigherThanStartTime',
		path: ['end'],
	})

export const MultiDatePicker = ({
	onChange,
}: {
	onChange: (timeslots: DateTimeSlot[]) => void
}) => {
	const { t } = useTranslation()

	const form = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		defaultValues: {
			days: [],
			start: 800,
			end: 2000,
		},
	})

	const onSubmit = (formValues: FormValues) => {
		const { convertToStringTime } = dateTimeUtils.utils
		const start = convertToStringTime(formValues.start)
		const end = convertToStringTime(formValues.end)
		const timeslots = formValues.days.map((day) => ({ day, start, end }))

		onChange(timeslots)
	}

	return (
		<Form {...form}>
			<form
				data-testid='form'
				noValidate
				onSubmit={form.handleSubmit(onSubmit)}
			>
				<div className='flex flex-col items-stretch justify-center gap-y-4'>
					<div>
						<Controller
							name='days'
							control={form.control}
							render={({ field }) => (
								<Calendar
									mode='multiple'
									numberOfMonths={2}
									className='pb-0'
									selected={field.value.map((d) => dateTimeUtils(d).toDate())}
									data-testid='day-picker'
									onSelect={(values) => {
										if (!values?.length) return

										const days = values?.map((value) =>
											dateTimeUtils(value).customFormat('dateTech'),
										)

										field.onChange(days)
									}}
								/>
							)}
						/>
					</div>

					<div className='grid w-full grid-cols-2 gap-4'>
						<FormControlledInput
							name='start'
							render={({ field }) => (
								<EditorTime
									name='start'
									labelKey='start'
									attribute={{
										name: 'start',
										type: 'time',
										editable: true,
									}}
									value={field.value}
									onChange={(time) => field.onChange(time)}
									onBlur={() => {}}
								/>
							)}
						/>

						<FormControlledInput
							name='end'
							render={({ field }) => (
								<EditorTime
									name='end'
									labelKey='end'
									attribute={{
										name: 'end',
										type: 'time',
										editable: true,
									}}
									value={field.value}
									onChange={(time) => field.onChange(time)}
									onBlur={() => {}}
								/>
							)}
						/>
					</div>

					<div className='flex items-center gap-x-4'>
						<Button
							type='submit'
							className='flex-1'
							data-testid='submit-btn'
						>
							{t('actions:add.btnLabel')}
						</Button>
					</div>
				</div>
			</form>
		</Form>
	)
}
