import type { AxiosError } from 'axios'
import type { SdkConfig } from '../../lib'

export const onErrorInterceptor =
	(sdkConfig: SdkConfig) => (err: AxiosError) => {
		if (sdkConfig.onApiError) {
			return sdkConfig.onApiError(err)
		}

		return err
	}
