import type { AxiosInstance } from 'axios'
import { addEventGuests } from '../endpoints/add-event-guests'
import { createBookingWithEvent } from '../endpoints/create-booking-with-event'
import { deleteEvent } from '../endpoints/delete-event'
import { deleteEventGuest } from '../endpoints/delete-event-guest'
import { getEvent } from '../endpoints/get-event'
import { getInvoiceFor } from '../endpoints/get-invoice-for'
import { listEventDirections } from '../endpoints/list-event-directions'
import { listEventExtras } from '../endpoints/list-event-extras'
import { listEvents } from '../endpoints/list-events'
import { listTopGuests } from '../endpoints/list-top-guests'
import { simulateEvent } from '../endpoints/simulate-event'
import { updateEvent } from '../endpoints/update-event'
import { updateEventExtra } from '../endpoints/update-event-extra'
import { updateEventGuest } from '../endpoints/update-event-guest'
import { updateEventGuests } from '../endpoints/update-event-guests'
import { updateEventOrganizer } from '../endpoints/update-event-organizer'

export const eventsModule = (axiosInstance: AxiosInstance) => ({
	getAll: listEvents(axiosInstance),
	getOne: getEvent(axiosInstance),
	createOne: createBookingWithEvent(axiosInstance),
	updateOne: updateEvent(axiosInstance),
	deleteOne: deleteEvent(axiosInstance),
	simulate: simulateEvent(axiosInstance),
	getDirections: listEventDirections(axiosInstance),
	getExtras: listEventExtras(axiosInstance),
	getInvoice: getInvoiceFor(axiosInstance),
	updateExtras: updateEventExtra(axiosInstance),
	/** @deprecated */
	sendGuestInvite: updateEventGuests(axiosInstance),
	addGuests: addEventGuests(axiosInstance),
	removeGuest: deleteEventGuest(axiosInstance),
	updateGuest: updateEventGuest(axiosInstance),
	sendOrganizerInvite: updateEventOrganizer(axiosInstance),
	listTopGuests: listTopGuests(axiosInstance),
})
