import type { AxiosRequestTransformer } from 'axios'

export const formDataTransformer: AxiosRequestTransformer = (data) => {
	const body = new FormData()

	for (const key of Object.keys(data)) {
		body.append(key, data[key])
	}

	return body
}
