import type { AxiosInstance } from 'axios'
import { formdataRequestOptions } from '../requestOptions/form-data'

interface ReplaceFile {
	config: {
		uriParams: {
			describeId: string
			fileId: string
		}
		data: {
			file: File
		}
	}
	response: {
		data: {
			blurhash: string
			id: string
			path: string
		}
	}
}

export const replaceFile =
	(axiosInstance: AxiosInstance) =>
	({ uriParams, data, ...config }: ReplaceFile['config']) =>
		axiosInstance.post<ReplaceFile['response']>(
			`/_admin/${uriParams.describeId}/files/${uriParams.fileId}`,
			data,
			{ ...formdataRequestOptions, ...config },
		)
