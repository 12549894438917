import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface AddEventGuests {
	uriParams: Api.Operations['addEventGuests']['parameters']['path']
	body: NonNullable<
		Api.Operations['addEventGuests']['requestBody']
	>['content']['application/json']
	response: Api.Operations['addEventGuests']['responses']['202']
}

export const addEventGuests = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<AddEventGuests>(
		{
			method: 'post',
			url: '/events/{eventId}/guests',
			...overideConfig,
		},
		axiosInstance,
	)
