import {
	type FirebaseApp,
	type FirebaseOptions,
	getApps,
	initializeApp,
} from 'firebase/app'
import {
	type Auth,
	type Dependencies,
	getAuth,
	initializeAuth,
} from 'firebase/auth'
import { PropsWithChildren, createContext, useContext, useMemo } from 'react'

export interface PrivateRoute {
	path: string
	mode: 'redirect' | 'dialog'
}

interface FirebaseAppProviderProps {
	firebaseAppName?: string
	firebaseConfig: FirebaseOptions
	firebaseAuthConfig?: Dependencies
	privateRoutes?: PrivateRoute[]
}

interface ContextType {
	firebaseApp: FirebaseApp
	firebaseAuth: Auth
	privateRoutes?: PrivateRoute[]
}

export const FirebaseAppContext = createContext<ContextType>({} as ContextType)

export const useFirebaseApp = () => useContext(FirebaseAppContext)
