import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface GetInvoiceTotals {
	params?: NonNullable<
		Api.Operations['getInvoiceTotals']['parameters']
	>['query']
	response: Api.Operations['getInvoiceTotals']['responses']['200']['content']['application/json']
}

export const getInvoiceTotals = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetInvoiceTotals>(
		{
			method: 'get',
			url: '/invoices/totals',
			...overideConfig,
		},
		axiosInstance,
	)
