import type { AdminModuleItem } from '@cocoonspace/types/admin/describe'
import type { AxiosInstance } from 'axios'

interface ListModules {
	response: {
		data: AdminModuleItem[]
	}
}

export const listModules = (axiosInstance: AxiosInstance) => () =>
	axiosInstance.get<ListModules['response']>('/_admin')
