import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import type { UseFormReturn } from 'react-hook-form'

const api_key = process.env.NEXT_PUBLIC_EMAILABLE_API_KEY as string

type Reason =
	| 'accepted_email'
	| 'invalid_domain'
	| 'invalid_email'
	| 'invalid_smtp'
	| 'low_deliverability'
	| 'low_quality'
	| 'no_connect'
	| 'rejected_email'
	| 'timeout'
	| 'unavailable_smtp'
	| 'unexpected_error'

type State = 'deliverable' | 'undeliverable' | 'risky' | 'unknown'

export interface EmailableResponse {
	accept_all: boolean
	did_you_mean: string
	disposable: boolean
	domain: string
	duration: number
	email: string
	first_name: string
	free: boolean
	full_name: string
	gender: string
	last_name: string
	mailbox_full: boolean
	mx_record: string
	no_reply: boolean
	reason: Reason
	role: boolean
	score: number
	smtp_provider: string
	state: State
	tag: string
	user: string
}

export const useValidateEmail = (form: UseFormReturn<any>) => {
	const { mutateAsync } = useMutation({
		mutationFn: (email: string) =>
			axios
				.get<EmailableResponse>('https://api.emailable.com/v1/verify?', {
					params: { api_key, email },
				})
				.then(({ data }) => data),
	})

	const validate = async (email: string) => {
		const res = await mutateAsync(email)

		if (
			(!!res.did_you_mean && res.state === 'undeliverable') ||
			res.disposable ||
			res.state === 'undeliverable'
		) {
			form.setError(
				'email',
				res.did_you_mean
					? { type: 'did_you_mean', message: res.did_you_mean }
					: {
							type: 'invalidEmail',
							message: 'errors:forms.invalidEmail',
						},
			)

			return false
		}

		return true
	}

	return {
		validate,
	}
}
