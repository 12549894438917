import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { Clock9Icon, Trash2Icon } from 'lucide-react'
import { type ComponentRef, forwardRef, useMemo, useRef, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '~/components/ui/popover'
import { Separator } from '~/components/ui/separator'
import type { BaseEditorProps } from '~/pages/[describeId]/_components/atoms/attribute-editor'

const BtnList = ({
	value,
	onChange,
	length,
}: {
	value?: string
	onChange: (value: string) => void
	length: number
}) => (
	<div className='grid grid-cols-8 gap-2 p-4 pt-1'>
		{Array.from({ length })
			.map((_, i) => i.toString().padStart(2, '0'))
			.map((time) => (
				<button
					key={time}
					type='button'
					disabled={value === time}
					className='h-6 w-6 rounded-full text-xs enabled:hover:bg-slate-100 disabled:bg-primary disabled:text-white'
					onClick={() => onChange(time)}
				>
					{time}
				</button>
			))}
	</div>
)

const EditorTimePopoverContent = ({
	value,
	onChange,
}: {
	value: { hours: string; minutes: string }
	onChange: (key: 'minutes' | 'hours', value: string) => void
}) => (
	<>
		<div className='flex flex-1 flex-col py-2'>
			<div className='text-center text-foreground/50 text-xs'>{'Heures'}</div>

			<BtnList
				length={24}
				value={value.hours}
				onChange={(hours) => {
					onChange('hours', hours)
				}}
			/>
		</div>

		<div className='flex flex-1 flex-col py-2'>
			<div className='text-center text-foreground/50 text-xs'>{'Minutes'}</div>

			<BtnList
				length={60}
				value={value.minutes}
				onChange={(minutes) => {
					onChange('minutes', minutes)
				}}
			/>
		</div>
	</>
)

export const EditorTime = forwardRef<ComponentRef<'input'>, BaseEditorProps>(
	({ attribute, labelKey, ...props }, ref) => {
		const minuteRef = useRef<HTMLInputElement>(null)
		const hourRef = useRef<HTMLInputElement>(null)

		const [tempValue, setTempValue] = useState({ hours: '', minutes: '' })

		const value = useMemo(() => {
			if (props.value === undefined || props.value === null)
				return { hours: '', minutes: '' }

			const [hours, minutes] = dateTimeUtils.utils
				.convertToStringTime(props.value)
				.split(':')

			return { hours, minutes }
		}, [props.value])

		const handleChange = ({
			hours,
			minutes,
		}: {
			hours?: string
			minutes?: string
		}) => {
			const finalHours = hours || tempValue.hours || value.hours
			const finalMinutes = minutes || tempValue.minutes || value.minutes

			if (finalHours?.length === 2 && finalMinutes?.length === 2) {
				props.onChange(Number(`${finalHours}${finalMinutes}`))
			}
		}

		return (
			<div className='relative flex h-9 w-full flex-row items-center rounded-md border border-input bg-transparent text-sm transition-colors file:border-0 file:bg-transparent file:font-medium file:text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50'>
				<Popover>
					<PopoverTrigger className='h-full px-2'>
						<Clock9Icon className='h-4 w-4' />
					</PopoverTrigger>

					<PopoverContent className='flex flex-col divide-y p-0'>
						<EditorTimePopoverContent
							value={tempValue}
							onChange={(key, updatedValue) => {
								setTempValue((ts) => ({ ...ts, [key]: updatedValue }))
								handleChange({ ...value, [key]: updatedValue })
							}}
						/>
					</PopoverContent>
				</Popover>

				<Separator orientation='vertical' />

				<div className='flex flex-1 flex-row items-center justify-center'>
					<NumericFormat
						getInputRef={hourRef}
						data-testid='hours-input'
						className='w-[50px] flex-1 appearance-none rounded-md px-3 py-1 text-right outline-0'
						value={value.hours || ''}
						isAllowed={(values) => {
							const { floatValue } = values

							return (floatValue || 0) < 24
						}}
						onFocus={(e) => e.target.select()}
						onKeyDown={(e) => {
							if (e.key === 'ArrowRight') minuteRef.current?.focus()
						}}
						onChange={(e) => {
							if (e.target.value?.length === 2) {
								minuteRef.current?.focus()

								setTempValue((ts) => ({ ...ts, hours: e.target.value }))

								handleChange({
									minutes: value.minutes,
									hours: e.target.value,
								})
							}
						}}
					/>

					{':'}

					<NumericFormat
						getInputRef={minuteRef}
						data-testid='minutes-input'
						className='w-[50px] flex-1 appearance-none rounded-md px-3 py-1 outline-0'
						value={value.minutes || ''}
						isAllowed={(values) => {
							const { floatValue } = values

							return (floatValue || 0) < 60
						}}
						onFocus={(e) => e.target.select()}
						onKeyDown={(e) => {
							if (e.key === 'ArrowLeft') hourRef.current?.focus()
						}}
						onChange={(e) => {
							if (e.target.value?.length === 2) {
								minuteRef.current?.blur()

								setTempValue((ts) => ({ ...ts, minutes: e.target.value }))

								handleChange({
									hours: value.hours,
									minutes: e.target.value,
								})
							}
						}}
					/>
				</div>

				<button
					type='button'
					disabled={!props.value}
					className='h-full w-[32px] px-2'
					onClick={() => {
						setTempValue({ hours: '', minutes: '' })
						props.onChange(null)
					}}
				>
					{!!props.value && <Trash2Icon className='h-4 w-4' />}
				</button>
			</div>
		)
	},
)

EditorTime.displayName = 'EditorTime'

export default EditorTime
