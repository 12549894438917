/* eslint-disable max-lines */
import type { GetEvent } from '@cocoonspace/sdk-js-legacy/endpoints/get-event'
import type { ListEventDirections } from '@cocoonspace/sdk-js-legacy/endpoints/list-event-directions'
import type { ListEventExtras } from '@cocoonspace/sdk-js-legacy/endpoints/list-event-extras'
import type { ListEvents } from '@cocoonspace/sdk-js-legacy/endpoints/list-events'
import type { ListTopGuests } from '@cocoonspace/sdk-js-legacy/endpoints/list-top-guests'
import type { SimulateEvent } from '@cocoonspace/sdk-js-legacy/endpoints/simulate-event'
import type { UpdateEvent } from '@cocoonspace/sdk-js-legacy/endpoints/update-event'
import type { UpdateEventGuest } from '@cocoonspace/sdk-js-legacy/endpoints/update-event-guest'
import type { Api } from '@cocoonspace/types/shared/api'
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import { useAuth } from '../../domains/auth/hooks/use-auth.hook'
import { cleanQueryKeyFactories } from '../../utils/clean-query-key-factories'
import { useCocoonApi } from './use-cocoon-api.hook'

export namespace EventHooksCtx {
	export interface list {
		filters?: ListEvents['params']
	}

	export interface detail {
		eventId: GetEvent['uriParams']['eventId']
		isAuth?: boolean
		filters?: GetEvent['params']
	}

	export interface topGuests {
		isAuth?: boolean
		filters?: ListTopGuests['params']
	}

	export interface listExtras {
		eventId: ListEventExtras['uriParams']['eventId']
		filters?: ListEventExtras['params']
	}

	export interface getDirections {
		eventId: ListEventDirections['uriParams']['eventId']
		filters?: ListEventDirections['params']
	}

	export interface getSimulation {
		eventId: SimulateEvent['uriParams']['eventId']
		data: SimulateEvent['body']
	}

	export interface checkUpdate {
		eventId: SimulateEvent['uriParams']['eventId']
		option?: Pick<Api.Event, 'day' | 'start' | 'end'>
	}
}

/**
 * Query keys
 */
export const EVENT_KEYS = cleanQueryKeyFactories({
	base: ['event'] as const,
	list: ({ filters }: EventHooksCtx.list) =>
		[...EVENT_KEYS.base, 'list', filters] as const,
	featured: () => [...EVENT_KEYS.base, 'featured'] as const,
	detail: ({ eventId, filters, isAuth }: EventHooksCtx.detail) =>
		[...EVENT_KEYS.base, 'detail', eventId, isAuth, filters] as const,
	listExtras: ({ eventId, filters }: EventHooksCtx.listExtras) =>
		[...EVENT_KEYS.base, 'extras', eventId, filters] as const,
	directions: ({ eventId, filters }: EventHooksCtx.getDirections) =>
		[...EVENT_KEYS.base, 'directions', eventId, filters] as const,
	simulation: ({ eventId, data }: EventHooksCtx.getSimulation) =>
		[...EVENT_KEYS.base, 'simulation', eventId, data] as const,
	topGuests: ({ filters }: EventHooksCtx.topGuests) =>
		[...EVENT_KEYS.base, 'topGuests', filters] as const,
} as const)

interface Options {
	enabled?: boolean
	keepPreviousData?: boolean
}

export const useEventList = (
	filters?: EventHooksCtx.list['filters'],
	opts: Options | undefined = {
		enabled: true,
		keepPreviousData: false,
	},
) => {
	const { getAll } = useCocoonApi('events')

	return useQuery({
		queryKey: EVENT_KEYS.list({ filters }),
		queryFn: () => getAll({ params: filters }).then((res) => res.data.data),
		enabled: opts.enabled,
		placeholderData: opts.keepPreviousData ? keepPreviousData : undefined,
	})
}

export const useEventExtrasList = (
	eventId: EventHooksCtx.listExtras['eventId'],
	filters?: EventHooksCtx.listExtras['filters'],
) => {
	const { isLoading } = useAuth()
	const { getExtras } = useCocoonApi('events')

	return useQuery({
		queryKey: EVENT_KEYS.listExtras({ eventId, filters }),
		queryFn: () =>
			getExtras({ uriParams: { eventId }, params: filters }).then(
				(res) => res.data.data,
			),
		enabled: !isLoading && !!eventId,
	})
}

export const useEventSimulation = (
	eventId: EventHooksCtx.getSimulation['eventId'],
	data: EventHooksCtx.getSimulation['data'],
) => {
	const { simulate } = useCocoonApi('events')

	return useQuery({
		queryKey: EVENT_KEYS.simulation({ eventId, data }),
		queryFn: () =>
			simulate({ uriParams: { eventId }, data }).then((res) => res.data.data),
		enabled: !!eventId && !!data,
		placeholderData: keepPreviousData,
	})
}

export const useEventUpdate = (
	eventId: string,
	opts?: { onSuccess: (data: Api.Event) => void },
) => {
	const { updateOne } = useCocoonApi('events')

	return useMutation({
		mutationFn: (data: UpdateEvent['body']) =>
			updateOne({ uriParams: { eventId }, data }).then((res) => res.data.data),
		onSuccess: opts?.onSuccess,
	})
}

export const useEventExtraUpdate = (eventId: string, params?: any) => {
	const { updateExtras } = useCocoonApi('events')

	return useMutation({
		mutationFn: ({
			id,
			...data
		}: Api.Schemas['EventExtraUpdateRequest'] & { id: string }) =>
			updateExtras({ uriParams: { eventId, extraId: id }, data, params }).then(
				(res) => res.data.data,
			),
		onSuccess: () => {},
	})
}

export const useEventUpdateGuest = (eventId: string) => {
	const { updateGuest } = useCocoonApi('events')

	return useMutation({
		mutationFn: ({
			email,
			data,
		}: { email: string; data: UpdateEventGuest['body'] }) =>
			updateGuest({ uriParams: { eventId, email }, data }).then(
				(res) => res.data,
			),
		onSuccess: () => {},
	})
}

export const useEventRemoveGuest = (eventId: string) => {
	const { removeGuest } = useCocoonApi('events')

	return useMutation({
		mutationFn: (email: string) =>
			removeGuest({ uriParams: { eventId, email } }).then((res) => res.data),
		onSuccess: () => {},
	})
}

export const useEventAssignOrga = (eventId: string) => {
	const { sendOrganizerInvite } = useCocoonApi('events')

	return useMutation({
		mutationFn: (data: { email: string }) =>
			sendOrganizerInvite({ uriParams: { eventId }, data }).then(
				(res) => res.data,
			),
		onSuccess: () => {},
	})
}

export const useListTopGuests = (
	filters?: EventHooksCtx.topGuests['filters'],
) => {
	const { isAuth, isLoading } = useAuth()
	const { listTopGuests } = useCocoonApi('events')

	return useQuery({
		queryKey: EVENT_KEYS.topGuests({ filters }),
		queryFn: () => listTopGuests({ params: filters }).then((data) => data.data),
		enabled: isAuth && !isLoading,
	})
}
