import { useEffect, useState } from 'react'

export const useDebounce = (value: any, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(() => {
		const handler = setTimeout(() => {
			if (JSON.stringify(value) !== JSON.stringify(debouncedValue)) {
				setDebouncedValue(value)
			}
		}, delay)

		return () => {
			clearTimeout(handler)
		}
	}, [value, delay, debouncedValue])

	return debouncedValue
}
