export const colors = [
	'#ff4338',
	'#22c55e',
	'#0ea5e9',
	'#a855f7',
	'#06b6d4',
	'#14b8a6',
	'#ec4899',
	'#f43f5e',
	'#55efc4',
	'#0984e3',
	'#d63031',
	'#fab1a0',
	'#81ecec',
	'#ff7675',
	'#00b894',
	'#fd79a8',
	'#a29bfe',
	'#6c5ce7',
	'#00cec9',
	'#fdcb6e',
	'#ffcccc',
	'#e17055',
	'#e84393',
	'#2d3436',
	'#7efff5',
	'#cd84f1',
	'#636e72',
	'#74b9ff',
	'#ff3838',
	'#18dcff',
	'#ffaf40',
	'#7158e2',
	'#c56cf0',
	'#ff4d4d',
	'#3ae374',
	'#67e6dc',
	'#17c0eb',
	'#32ff7e',
	'#7d5fff',
]
