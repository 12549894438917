import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAdminApi } from '~/lib/sdk-admin-js'
import type { CustomAction } from '~/lib/sdk-admin-js/endpoints/custom-action-module-entry'
import type { UpdateModuleEntry } from '~/lib/sdk-admin-js/endpoints/update-module-entry'

export namespace EntryHooksCtx {
	export interface list {
		describeId: string
		params?: Record<string, any>
	}

	export interface details {
		describeId: string
		entryId: string
		params?: Record<string, any>
	}
}

export const ENTRY_KEYS = {
	base: ['entry'],
	list: ({ describeId, params }: EntryHooksCtx.list) => [
		...ENTRY_KEYS.base,
		'list',
		describeId,
		params,
	],
	details: ({ describeId, entryId, params }: EntryHooksCtx.details) => [
		...ENTRY_KEYS.base,
		'details',
		describeId,
		entryId,
		params,
	],
}

/** Retrieve Entity list */
export const useEntriesList = (
	describeId: EntryHooksCtx.list['describeId'],
	params?: EntryHooksCtx.list['params'],
) => {
	const $adminApi = useAdminApi()

	return useQuery({
		queryKey: ENTRY_KEYS.list({ describeId, params }),
		queryFn: () =>
			$adminApi
				.listModuleEntries({ uriParams: { describeId }, params })
				.then((res) => res.data),
		enabled: !!describeId,
		staleTime: 1000,
		retry: false,
	})
}

/** Retrieve Entity specific entry's data */
export const useEntryDetails = <T = Record<string, any>>(
	describeId: EntryHooksCtx.details['describeId'],
	entryId: EntryHooksCtx.details['entryId'],
	params?: Record<string, any>,
) => {
	const $adminApi = useAdminApi()

	return useQuery({
		queryKey: ENTRY_KEYS.details({ describeId, entryId, params }),
		queryFn: () =>
			$adminApi
				.getModuleEntry<T>({ uriParams: { describeId, entryId }, params })
				.then((res) => res.data),
		enabled: !!describeId && !!entryId,
		staleTime: 1000,
	})
}

/** Update a specific Entity data */
export const useEntryUpdate = (describeId: string) => {
	const queryClient = useQueryClient()
	const $adminApi = useAdminApi()

	return useMutation({
		mutationFn: ({
			data,
			params,
		}: Omit<UpdateModuleEntry['config'], 'uriParams'>) =>
			$adminApi
				.updateModuleEntry({ uriParams: { describeId }, data, params })
				.then((res) => res.data),
		onSuccess: (data) => {
			queryClient.refetchQueries({
				queryKey: ENTRY_KEYS.list({ describeId }),
				type: 'active',
			})

			queryClient.refetchQueries({
				queryKey: ENTRY_KEYS.details({ describeId, entryId: data.data.id }),
				type: 'active',
			})
		},
	})
}

export const useEntryCustomAction = () => {
	const $adminApi = useAdminApi()

	const mutationFn = ({
		describeId,
		entryId,
		action,
	}: CustomAction['config']['uriParams']) =>
		$adminApi.customActionModuleEntry({
			uriParams: { describeId, entryId, action },
		})

	return useMutation({ mutationFn })
}
