import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface UpdateAccountUser {
	uriParams: Api.Operations['updateAccountUser']['parameters']['path']
	body: NonNullable<
		Api.Operations['updateAccountUser']['requestBody']
	>['content']['application/json']
	response: Api.Operations['updateAccountUser']['responses']['200']['content']['application/json']
}

export const updateAccountUser = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<UpdateAccountUser>(
		{
			method: 'patch',
			url: '/account/users/{userId}',
			...overideConfig,
		},
		axiosInstance,
	)
