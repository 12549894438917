import { useMediaQuery } from '@cocoonspace/shared/hooks/use-media-query.hook'
import type { PropsWithChildren } from 'react'
import { useUserPrefsStore } from '~/stores/user-prefs.store'
import { AppMenu } from './app-menu'

export const PageHeaderWrapper = ({ children }: PropsWithChildren) => {
	const isDesktop = useMediaQuery('(min-width: 768px)')
	const isSidebarVisible = useUserPrefsStore.use.isSidebarVisible()

	return (
		<header className='flex min-h-[53px] w-full flex-row items-center gap-4 border-b bg-slate-50 px-4 py-2 dark:bg-slate-800'>
			{(!isSidebarVisible || !isDesktop) && <AppMenu />}

			{children}
		</header>
	)
}
