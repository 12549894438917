import type { AxiosInstance } from 'axios'

export interface UpdateModuleEntry {
	config: {
		uriParams: {
			describeId: string
		}
		params?: {
			scopes?: string
			fields?: string[]
		}
		data: any
	}
	response: {
		data: any
	}
}

export const updateModuleEntry =
	(axiosInstance: AxiosInstance) =>
	({ uriParams, data, ...config }: UpdateModuleEntry['config']) =>
		axiosInstance.post<UpdateModuleEntry['response']>(
			`/_admin/${uriParams.describeId}`,
			data,
			config,
		)
