import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface GetAccountCodes {
	response: Api.Operations['getAccountCodes']['responses']['200']['content']['application/json']
}

export const getAccountCodes = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetAccountCodes>(
		{
			method: 'get',
			url: '/account/codes',
			...overideConfig,
		},
		axiosInstance,
	)
