import type { EntityLink } from '@cocoonspace/types/admin/describe'
import type { AxiosInstance } from 'axios'

export interface EntryReturnPayload<T> {
	data: T
	relationships?: {
		[key: string]: any
	}
	links?: EntityLink[]
}

interface GetModuleEntry<T> {
	config: {
		uriParams: {
			describeId: string
			entryId: string
		}
		params?: {
			scopes?: string
		}
	}
	response: EntryReturnPayload<T>
}

export const getModuleEntry =
	(axiosInstance: AxiosInstance) =>
	<T = Record<string, any>>({
		uriParams,
		...config
	}: GetModuleEntry<T>['config']) =>
		axiosInstance.get<GetModuleEntry<T>['response']>(
			`/_admin/${uriParams.describeId}/${uriParams.entryId}`,
			config,
		)
