import type { AxiosInstance } from 'axios'

interface CreateModuleEntry {
	config: {
		uriParams: {
			describeId: string
		}
		data: any
	}
	response: {
		data: any
	}
}

export const createModuleEntry =
	(axiosInstance: AxiosInstance) =>
	({ uriParams, data, ...config }: CreateModuleEntry['config']) =>
		axiosInstance.post<CreateModuleEntry['response']>(
			`/_admin/${uriParams.describeId}`,
			data,
			config,
		)
