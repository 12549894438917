import type { EntityLink } from '@cocoonspace/types/admin/describe'
import type { AxiosInstance } from 'axios'

interface DeleteModuleEntry {
	config: {
		uriParams: {
			describeId: string
			entryId: string
		}
	}
	response: {
		data: any
		links?: EntityLink[]
	}
}

export const deleteModuleEntry =
	(axiosInstance: AxiosInstance) =>
	({ uriParams, ...config }: DeleteModuleEntry['config']) =>
		axiosInstance.delete<DeleteModuleEntry['response']>(
			`/_admin/${uriParams.describeId}/${uriParams.entryId}`,
			config,
		)
