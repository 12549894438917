import { useQuery } from '@tanstack/react-query'
import { HomeIcon, PanelRightOpenIcon, SearchIcon } from 'lucide-react'
import { Trans, useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { type PropsWithChildren, useEffect } from 'react'
import { DescribeIcon } from '~/components/describe-icon'
import { Button, buttonVariants } from '~/components/ui/button'
import { UserMenu } from '~/components/user-menu'
import type { EntityName } from '~/config/entities'
import { dialogsEmitter } from '~/emitters/dialogs.emitter'
import { useAdminApi } from '~/lib/sdk-admin-js'
import { useUserPrefsStore } from '~/stores/user-prefs.store'

const useSidebarShortcuts = () => {
	const router = useRouter()

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === 'e' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				router.push('/events')
			} else if (e.key === 'p' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				router.push('/planning')
			} else if (e.key === 'u' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				router.push('/users')
			}
		}

		document.addEventListener('keydown', down)

		return () => document.removeEventListener('keydown', down)
	}, [router.push])
}

const SidebarGroup = ({
	entityName,
	children,
}: PropsWithChildren<{ entityName: EntityName }>) => (
	<div data-testid='sidebar-group'>
		<Trans
			parent='div'
			i18nKey={`modules:${entityName}_one`}
			data-testid='sidebar-group-label'
			className='mb-1 flex w-full flex-row items-center truncate text-[10px] text-slate-500 uppercase'
		/>

		<div>{children}</div>
	</div>
)

const SidebarItemLink = ({
	entityName,
}: {
	entityName: EntityName | 'planning'
}) => {
	const router = useRouter()
	const { t } = useTranslation()

	const isActive = router.asPath.startsWith(`/${entityName}`)

	return (
		<Link
			data-testid='sidebar-group-link'
			href={`/${entityName}`}
			className={buttonVariants({
				size: 'sm',
				variant: isActive ? 'outline' : 'ghost',
				className: '!justify-start flex w-full gap-2',
			})}
		>
			<DescribeIcon
				describeId={entityName}
				className='size-3'
			/>

			<div className='flex-1'>{t(`modules:${entityName}_other`)}</div>

			{entityName === 'events' && <span className='text-slate-400'>⌘E</span>}
			{entityName === 'users' && <span className='text-slate-400'>⌘U</span>}
			{entityName === 'planning' && <span className='text-slate-400'>⌘P</span>}
		</Link>
	)
}

const Menu = () => {
	const router = useRouter()
	const $adminApi = useAdminApi()

	const { data: availEntities } = useQuery({
		queryKey: ['sidebar'],
		queryFn: () => $adminApi.listModules(),
		select: (res) =>
			res.data?.data.reduce(
				(acc, module) => {
					acc[module.id as keyof typeof acc] = !module.hidden
					return acc
				},
				{} as Record<string, boolean>,
			) || {},
	})

	return (
		<div className='flex flex-1 flex-col gap-2 overflow-y-auto p-4'>
			<Link
				data-testid='sidebar-group-link'
				href='/'
				className={buttonVariants({
					size: 'sm',
					variant: router.route === '/' ? 'outline' : 'ghost',
					className: '!justify-start flex w-full gap-2 py-2',
				})}
			>
				<HomeIcon className='size-3' />

				<div className='flex-1'>Accueil</div>
			</Link>

			<SidebarGroup entityName='events'>
				{availEntities?.events && <SidebarItemLink entityName='events' />}
				{availEntities?.quotations && (
					<SidebarItemLink entityName='quotations' />
				)}
				<SidebarItemLink entityName='planning' />
				{availEntities?.invoices && <SidebarItemLink entityName='invoices' />}
				{availEntities?.overstays && <SidebarItemLink entityName='overstays' />}
			</SidebarGroup>

			{(availEntities?.users || availEntities?.accounts) && (
				<SidebarGroup entityName='users'>
					{availEntities?.users && <SidebarItemLink entityName='users' />}
					{availEntities?.accounts && <SidebarItemLink entityName='accounts' />}
				</SidebarGroup>
			)}

			{(availEntities?.extras ||
				availEntities?.providers ||
				availEntities?.orders) && (
				<SidebarGroup entityName='extras'>
					{availEntities?.extras && <SidebarItemLink entityName='extras' />}
					{availEntities?.providers && (
						<SidebarItemLink entityName='providers' />
					)}
					{availEntities?.orders && <SidebarItemLink entityName='orders' />}
				</SidebarGroup>
			)}

			{(availEntities?.spaces || availEntities?.venues) && (
				<SidebarGroup entityName='spaces'>
					{availEntities?.spaces && <SidebarItemLink entityName='spaces' />}
					{availEntities?.venues && <SidebarItemLink entityName='venues' />}
				</SidebarGroup>
			)}

			{(availEntities?.cohorts ||
				availEntities?.rules ||
				availEntities?.denylist) && (
				<SidebarGroup entityName='rules'>
					{availEntities?.cohorts && <SidebarItemLink entityName='cohorts' />}
					{availEntities?.rules && <SidebarItemLink entityName='rules' />}
					{availEntities?.denylist && <SidebarItemLink entityName='denylist' />}
				</SidebarGroup>
			)}

			{availEntities?.params && (
				<SidebarGroup entityName='params'>
					<SidebarItemLink entityName='params' />
				</SidebarGroup>
			)}
		</div>
	)
}

export const Sidebar = () => {
	const { t } = useTranslation()
	const toggleSidebar = useUserPrefsStore.use.toggleSidebar()

	useSidebarShortcuts()

	return (
		<aside
			data-testid='sidebar'
			className='flex w-[180px] flex-col divide-y overflow-hidden rounded-lg border-r bg-card max-md:w-full dark:bg-card'
		>
			{process.env.NEXT_PUBLIC_APP_ENV === 'preprod' && (
				<div className='bg-primary p-1 text-center font-semibold text-primary-foreground text-xxs uppercase'>
					preprod
				</div>
			)}

			<div className='flex flex-row items-center justify-between p-2'>
				<UserMenu />
			</div>

			<div className='p-4'>
				<Button
					variant='outline'
					className='w-full text-xs md:hidden'
					onClick={() => toggleSidebar()}
				>
					<PanelRightOpenIcon className='mr-2 size-4 text-sm' />
					Cacher le menu
				</Button>

				<Button
					className='w-full text-xs max-md:hidden'
					onClick={() => dialogsEmitter.emit('availabilities.open')}
				>
					<SearchIcon className='mr-2 size-4 text-sm' />

					{t('quotations:availabilities.checkAvailBtn')}
				</Button>
			</div>

			<Menu />
		</aside>
	)
}
