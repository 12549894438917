import {
	ArchiveIcon,
	ArmchairIcon,
	BanIcon,
	BuildingIcon,
	CalendarClockIcon,
	CalendarIcon,
	CalendarSearchIcon,
	CheckIcon,
	CopyIcon,
	CroissantIcon,
	EllipsisVerticalIcon,
	EyeIcon,
	FilePenIcon,
	FileSpreadsheetIcon,
	GripVerticalIcon,
	GroupIcon,
	LinkIcon,
	LogOutIcon,
	PackageCheckIcon,
	PackageIcon,
	PackageXIcon,
	PenIcon,
	PlusIcon,
	PrinterIcon,
	RotateCwIcon,
	SaveIcon,
	ScaleIcon,
	SettingsIcon,
	TicketPlusIcon,
	Trash2Icon,
	TruckIcon,
	Undo2Icon,
	UserRoundCheckIcon,
	UserRoundIcon,
	UsersRoundIcon,
	XIcon,
} from 'lucide-react'

export const iconsRef = {
	describes: {
		accounts: UsersRoundIcon,
		cohorts: GroupIcon,
		events: CalendarIcon,
		extras: CroissantIcon,
		invoices: FileSpreadsheetIcon,
		params: SettingsIcon,
		orders: PackageIcon,
		overstays: CalendarClockIcon,
		planning: CalendarSearchIcon,
		providers: TruckIcon,
		quotations: FilePenIcon,
		rules: ScaleIcon,
		denylist: BanIcon,
		spaces: ArmchairIcon,
		users: UserRoundIcon,
		venues: BuildingIcon,
	},
	actions: {
		archive: ArchiveIcon,
		cancel_event: BanIcon,
		cancel: BanIcon,
		close: XIcon,
		create: PlusIcon,
		createEntry: PlusIcon,
		createLink: LinkIcon,
		delete: Trash2Icon,
		drag: GripVerticalIcon,
		duplicate: CopyIcon,
		edit: PenIcon,
		logout: LogOutIcon,
		menu: EllipsisVerticalIcon,
		print: PrinterIcon,
		reassign: UserRoundCheckIcon,
		rebuild: RotateCwIcon,
		reject_order: PackageXIcon,
		save: SaveIcon,
		sorry_code: TicketPlusIcon,
		undo: Undo2Icon,
		validate_order: PackageCheckIcon,
		validate: CheckIcon,
		view: EyeIcon,
		void: Trash2Icon,
	},
} as const
