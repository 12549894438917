import axios, { type AxiosError, type AxiosRequestConfig } from 'axios'
import { stringify } from 'qs'
import { authTokenInterceptor } from './interceptors/request/auth-token'
import { onErrorInterceptor } from './interceptors/response/on-error'
import { accountModule } from './modules/accounts'
import { autocompleteModule } from './modules/autocomplete'
import { bookingModule } from './modules/bookings'
import { eventsModule } from './modules/events'
import { extraModule } from './modules/extras'
import { invoicesModule } from './modules/invoices'
import { paymentMethodsModule } from './modules/payment'
import { spacesModule } from './modules/spaces'
import { usersModule } from './modules/users'

export enum EnvBaseUrl {
	test = 'https://api-test.cocoon-space.com/v1',
	preprod = 'https://api-preprod.cocoon-space.com/v1',
	beta = 'https://api-beta.cocoon-space.com/v1',
	prod = 'https://api.cocoon-space.com/v1',
}

export type Envs = keyof typeof EnvBaseUrl

export type ReqConfig<UriParams, QueryParams, Body> = Omit<
	AxiosRequestConfig<Body>,
	'params'
> & {
	uriParams?: UriParams
	params?: QueryParams
}

export interface SdkConfig {
	env: Envs
	defaultAxiosOptions?: AxiosRequestConfig
	getAuthToken?: (config: AxiosRequestConfig) => Promise<string>
	onApiError?: (config: AxiosError) => void
}

const createSdk = (sdkConfig: SdkConfig) => {
	const axiosInstance = axios.create({
		baseURL: EnvBaseUrl[sdkConfig.env],
		paramsSerializer: (params) =>
			stringify(params, { arrayFormat: 'indices', allowDots: true }),
		...sdkConfig.defaultAxiosOptions,
	})

	axiosInstance.interceptors.request.use(authTokenInterceptor(sdkConfig))

	axiosInstance.interceptors.request.use(
		(res) => res,
		onErrorInterceptor(sdkConfig),
	)

	return {
		_sdkConfig: sdkConfig,
		_axiosInstance: axiosInstance,
		accounts: accountModule(axiosInstance),
		autocomplete: autocompleteModule(axiosInstance),
		bookings: bookingModule(axiosInstance),
		events: eventsModule(axiosInstance),
		extras: extraModule(axiosInstance),
		spaces: spacesModule(axiosInstance),
		users: usersModule(axiosInstance),
		paymentMethods: paymentMethodsModule(axiosInstance),
		invoices: invoicesModule(axiosInstance),
	}
}

export { createSdk }
