import type { GetBooking } from '@cocoonspace/sdk-js-legacy/endpoints/get-booking'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../../domains/auth/hooks/use-auth.hook'
import { cleanQueryKeyFactories } from '../../utils/clean-query-key-factories'
import { useCocoonApi } from './use-cocoon-api.hook'

export namespace BookingHooksCtx {
	export interface detail {
		bookingId: GetBooking['uriParams']['bookingId']
	}
}

export const BOOKING_KEYS = cleanQueryKeyFactories({
	base: ['booking'] as const,
	detail: ({ bookingId }: BookingHooksCtx.detail) =>
		[...BOOKING_KEYS.base, 'detail', bookingId] as const,
	findOrCreate: () => [...BOOKING_KEYS.base, 'findOrCreate'] as const,
} as const)

export const useBookingDetails = (
	bookingId: BookingHooksCtx.detail['bookingId'],
	params?: any,
) => {
	const { isAuth, isLoading } = useAuth()
	const { getOne } = useCocoonApi('bookings')

	return useQuery({
		queryKey: BOOKING_KEYS.detail({ bookingId }),
		queryFn: () =>
			getOne({ uriParams: { bookingId }, params }).then((res) => res.data.data),
		enabled: !isLoading && isAuth && !!bookingId,
	})
}
