import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface CreateBookingWithEvent {
	body: NonNullable<
		Api.Operations['createBookingWithEvent']['requestBody']
	>['content']['application/json']
	response: Api.Operations['createBookingWithEvent']['responses']['200']['content']['application/json']
}

export const createBookingWithEvent = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<CreateBookingWithEvent>(
		{
			method: 'post',
			url: '/events',
			...overideConfig,
		},
		axiosInstance,
	)
