import Image from 'next/image'

export const PageLoader = () => (
	<div
		data-testid='pageLoader'
		className='flex h-full flex-col items-center justify-center bg-card/70'
	>
		<div className='absolute h-[50px] w-[50px] animate-ping rounded-full bg-[red]/10 duration-[2000ms]' />

		<Image
			src='/images/cocoon-logo.svg'
			width={70}
			height={70}
			alt='cocoon space'
			className='relative z-10 animate-pulse'
			priority
		/>
	</div>
)
