import type { AxiosInstance } from 'axios'
import { getAnyAccount } from '../endpoints/get-any-account'
import { addAccountUser } from './../endpoints/add-account-user'
import { deleteAccountUser } from './../endpoints/delete-account-user'
import { getAccount } from './../endpoints/get-account'
import { getAccountCodes } from './../endpoints/get-account-codes'
import { getAccountUsers } from './../endpoints/get-account-users'
import { updateAccount } from './../endpoints/update-account'
import { updateAccountUser } from './../endpoints/update-account-user'

export const accountModule = (axiosInstance: AxiosInstance) => {
	return {
		getCurrent: getAccount(axiosInstance),
		getAnyAccount: getAnyAccount(axiosInstance),
		updateCurrent: updateAccount(axiosInstance),
		addUser: addAccountUser(axiosInstance),
		getAllUsers: getAccountUsers(axiosInstance),
		updateUser: updateAccountUser(axiosInstance),
		getCodes: getAccountCodes(axiosInstance),
		deleteUser: deleteAccountUser(axiosInstance),
	}
}
