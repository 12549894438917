import { type ReactNode, useState } from 'react'

export const useCopyClipboard = (timeout = 1000) => {
	const [isCopied, setIsCopied] = useState(false)

	const copyToClipboard = (value: string | number | ReactNode) => {
		navigator.clipboard.writeText(value as string)

		setIsCopied(true)

		setTimeout(() => {
			setIsCopied(false)
		}, timeout)
	}

	return {
		isCopied,
		copyToClipboard,
	}
}
