import type { AxiosInstance } from 'axios'
import { formdataRequestOptions } from '../requestOptions/form-data'

interface UploadFile {
	config: {
		uriParams: {
			describeId: string
		}
		data: {
			file: File
		}
	}
	response: {
		data: {
			blurhash: string
			id: string
			path: string
		}
	}
}

export const uploadFile =
	(axiosInstance: AxiosInstance) =>
	({ uriParams, data, ...config }: UploadFile['config']) =>
		axiosInstance.post<UploadFile['response']>(
			`/_admin/${uriParams.describeId}/files`,
			data,
			{ ...formdataRequestOptions, ...config },
		)
