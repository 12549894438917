import type { Space } from '@cocoonspace/sdk-js/types/space'
import { useCopyClipboard } from '@cocoonspace/shared/hooks/use-copy-clipboard.hook'
import { ClipboardCheckIcon, ClipboardIcon, MapPinIcon } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import { toast } from 'sonner'
import { Button } from '~/components/ui/button'

export const SpaceCellRenderer = ({
	row,
}: {
	row: { space: Space }
}) => {
	const { t } = useTranslation()
	const { copyToClipboard, isCopied } = useCopyClipboard()

	return (
		<div className='flex flex-row items-center gap-2 overflow-hidden'>
			<Button
				variant='outline'
				size='sm'
				className='!px-2'
				onClick={() => {
					copyToClipboard(
						`https://www.cocoon-space.com/${row.space.zone}/${row.space.slug}`,
					)

					toast.success(t('actions:copyCode.successMessage'))
				}}
			>
				{isCopied ? (
					<ClipboardCheckIcon className='size-4' />
				) : (
					<ClipboardIcon className='size-4' />
				)}
			</Button>

			<div className='flex min-w-0 flex-col justify-around gap-1 overflow-hidden leading-none'>
				{`${row.space.venue_name} - ${row.space.name}`}

				<div className='flex flex-row items-center gap-1 truncate text-slate-700 text-xs leading-none'>
					<MapPinIcon className='size-3' />

					{`${row.space.address.address1} - ${row.space.address.city} ${row.space.address.postal_code}`}
				</div>
			</div>
		</div>
	)
}
