import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface SetDefaultAccountPaymentMethod {
	uriParams: Api.Operations['setDefaultAccountPaymentMethod']['parameters']['path']
	response: Api.Operations['setDefaultAccountPaymentMethod']['responses']['202']
}

export const setDefaultAccountPaymentMethod = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<SetDefaultAccountPaymentMethod>(
		{
			method: 'post',
			url: '/account/payment_methods/{methodId}/default',
			...overideConfig,
		},
		axiosInstance,
	)
