import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { CheckIcon, MoonIcon, SunIcon } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'next-themes'
import { useRouter } from 'next/router'
import { ActionIcon } from '~/components/action-icon'
import { Avatar, AvatarFallback, AvatarImage } from '~/components/ui/avatar'
import { Button } from '~/components/ui/button'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '~/components/ui/tooltip'
import { cn } from '~/lib/utils'
import { accents, useUserPrefsStore } from '~/stores/user-prefs.store'
import { Card } from './ui/card'

export type Mode = 'light' | 'dark'

const UserInfos = () => {
	const { fireAuthUser } = useAuth()

	const initials = fireAuthUser?.displayName
		?.split(' ')
		.map((text) => text[0])
		.join('')

	return (
		<div className='flex w-full grow-0 flex-row items-center gap-2'>
			<Avatar className='h-8 w-8'>
				<AvatarImage src={fireAuthUser?.photoURL as string} />

				<AvatarFallback>{initials}</AvatarFallback>
			</Avatar>

			<div className='min-w-0 text-left text-xs'>
				<div className='font-bold'>{fireAuthUser?.displayName}</div>
				<div className='truncate'>{fireAuthUser?.email}</div>
			</div>
		</div>
	)
}

const AccentColor = () => {
	const { theme, setTheme } = useTheme()
	const accentColor = useUserPrefsStore.use.accentColor()
	const setAccentColor = useUserPrefsStore.use.setAccentColor()

	return (
		<div className='flex flex-col flex-wrap gap-1 p-2'>
			<div className='text-sm'>Thème :</div>

			<div className='flex flex-row items-center gap-2'>
				<Card className='divide-x overflow-hidden border'>
					<Button
						variant='ghost'
						size='icon'
						disabled={theme === 'light'}
						className='rounded-none'
						onClick={() => setTheme('light')}
					>
						<SunIcon className='!stroke-[2px] size-4' />
					</Button>

					<Button
						variant='ghost'
						size='icon'
						disabled={theme === 'dark'}
						className='rounded-none'
						onClick={() => setTheme('dark')}
					>
						<MoonIcon className='!stroke-[2px] size-4' />
					</Button>
				</Card>

				<Card className='divide-x overflow-hidden border'>
					{accents.map((accent) => (
						<Tooltip key={accent}>
							<TooltipTrigger>
								<Button
									variant={accent === accentColor ? 'default' : 'ghost'}
									size='icon'
									className='rounded-none'
									// disabled={accent === accentColor}
									onClick={() => setAccentColor(accent)}
								>
									<div
										className={cn(
											'hover:!bg-primary flex size-4 items-center justify-center rounded-full bg-primary text-primary-foreground',
											`accent-${accent}`,
											theme,
										)}
									>
										{accent === accentColor && (
											<CheckIcon className='!stroke-[4px] size-4' />
										)}
									</div>
								</Button>
							</TooltipTrigger>

							<TooltipContent className='capitalize'>{accent}</TooltipContent>
						</Tooltip>
					))}
				</Card>
			</div>
		</div>
	)
}

export const UserMenu = () => {
	const router = useRouter()
	const { t } = useTranslation()
	const { logout } = useAuth()

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant='ghost'
					className='h-auto w-full p-0'
				>
					<UserInfos />
				</Button>
			</DropdownMenuTrigger>

			<DropdownMenuContent
				className='max-w-[300px]'
				sideOffset={12}
				align='start'
				alignOffset={4}
			>
				<DropdownMenuLabel>
					<UserInfos />
				</DropdownMenuLabel>

				<DropdownMenuSeparator />

				<AccentColor />

				<DropdownMenuSeparator />

				<DropdownMenuItem onClick={() => logout().then(() => router.push('/'))}>
					<ActionIcon
						actionName='logout'
						className='mr-2 inline'
					/>

					{t('actions:logout.btnLabel')}
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export default UserMenu
