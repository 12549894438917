import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface DeleteMe {
	response: Api.Operations['deleteMe']['responses']['204']['content']['application/json']
}

export const deleteMe = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<DeleteMe>(
		{
			method: 'delete',
			url: '/me',
			...overideConfig,
		},
		axiosInstance,
	)
