import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defineEndpoint } from '../utils/define-endpoint'

export interface ListSpaceAvailabilities {
	params?: {
		type?: string
	}
	body: Api.Schemas['AvailabilitiesRequest']
	response: {
		data: (Api.Schemas['Availability'] & {
			space_id: string
			day: string
			start: string
			end: string
			price_hour_discounted?: number
			rebate?: number
			rebate_percent?: number
		})[]
	}
}

export const listSpaceAvailabilities = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<ListSpaceAvailabilities>(
		{
			method: 'post',
			url: '/availabilities',
			...overideConfig,
		},
		axiosInstance,
	)
