import { i18n } from 'next-i18next'
import { toast } from 'sonner'

export const onErrorInterceptor = (err: any) => {
	console.info('API ERROR: ', err) // eslint-disable-line

	toast.error(
		i18n?.t([
			`errors:api.${err.response?.data?.errors[0].code}`,
			err.response?.data?.errors[0].title,
		]),
	)

	return Promise.reject(err)
}
