import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { SvgApple } from '~/components/icons/font-awesome/brands/apple'
import { SvgFacebookF } from '~/components/icons/font-awesome/brands/facebook-f'
import { SvgGoogle } from '~/components/icons/font-awesome/brands/google'

export const SocialAuthList = () => {
	const { login } = useAuth()

	return (
		<div className='flex flex-row items-center justify-center gap-4'>
			<button
				type='button'
				className='flex h-10 w-10 items-center justify-center rounded-full bg-white text-gray-800 shadow-[0_0_12px_-7px_rgb(0_0_0_/_70%)]'
				onClick={() => login('google')}
			>
				<SvgGoogle className='text-lg' />
			</button>

			<button
				type='button'
				className='flex h-10 w-10 items-center justify-center rounded-full bg-black text-white shadow-[0_0_12px_-7px_rgb(0_0_0_/_70%)]'
				onClick={() => login('apple')}
			>
				<SvgApple className='text-lg' />
			</button>

			<button
				type='button'
				className='flex h-10 w-10 items-center justify-center rounded-full bg-[#3B5A9A] text-white shadow-[0_0_12px_-7px_rgb(0_0_0_/_70%)]'
				onClick={() => login('facebook')}
			>
				<SvgFacebookF className='text-lg' />
			</button>
		</div>
	)
}
