import { useListSpaceAvailabilities } from '@cocoonspace/sdk-js/domains/availabilities/availabilities'
import { useListSpaces } from '@cocoonspace/sdk-js/domains/spaces/spaces'
import type { Availability } from '@cocoonspace/sdk-js/types/availability'
import type { Event } from '@cocoonspace/sdk-js/types/event'
import type { Space } from '@cocoonspace/sdk-js/types/space'
import { keepPreviousData } from '@tanstack/react-query'
import { useMemo } from 'react'
import { genDateTimeUniqueKey } from '../_utils/gen-date-time-unique-key'
import { useTimeSlots } from './use-time-slots.hook'

export type Slot = Pick<Event, 'day' | 'start' | 'end'> & { space_id: string }

export interface AvailableTimeSlots {
	space: Space
	availabilities: Availability[]
	countAvail: number
	totalAvail: number
	percentAvail: number
	totalPrice: number
}

interface AvailQuery {
	day: string
	start: string
	end: string
	space: string
	type: 'quotation'
}

export const useBookingSimulation = ({ spaceParams }: { spaceParams: any }) => {
	const { slotsState, dispatchSlots } = useTimeSlots()

	const spacesQuery = useListSpaces(spaceParams, {
		query: {
			enabled: !!slotsState.slots.length,
		},
	})

	const availBody = useMemo(
		() =>
			spacesQuery.data?.data?.reduce<AvailQuery[]>((acc, space) => {
				const fullSlots = slotsState.slots.map((slot) => ({
					...slot,
					space: space.id,
					type: 'quotation' as const,
				})) as AvailQuery[]

				acc.push(...fullSlots)

				return acc
			}, []),
		[spacesQuery.data, slotsState.slots],
	)

	// biome-ignore lint/style/noNonNullAssertion: <explanation>
	const availQuery = useListSpaceAvailabilities(availBody!, {
		query: { enabled: !!availBody?.length, placeholderData: keepPreviousData },
	})

	const spacesWithAvail = useMemo(() => {
		if (!spacesQuery.data) return []

		return spacesQuery.data?.data
			.map((space) => {
				const availabilities =
					availQuery.data?.data
						.filter((row) => row.space_id === space.id)
						.map((item) => {
							const priceHour = item?.price_hour || 0
							const billedMinutes = item?.billed_minutes || 0
							const totalPrice = priceHour * (billedMinutes / 60)

							return {
								...item,
								id: genDateTimeUniqueKey({
									// @ts-expect-error
									day: item.day,
									// @ts-expect-error
									start: item.start,
									// @ts-expect-error
									end: item.end as any,
								}),
								totalPrice,
							}
						}) || []

				const countAvail =
					availabilities?.filter(({ available }) => available).length || 0

				const totalAvail = availabilities?.length || 0
				const percentAvail = (countAvail / totalAvail) * 100

				return {
					space: space,
					availabilities,
					countAvail,
					totalAvail,
					percentAvail,
					totalPrice:
						availabilities?.reduce(
							(acc, item) => acc + (item.totalPrice || 0),
							0,
						) || 0,
				}
			})
			.sort((a, b) => {
				if (a.percentAvail > b.percentAvail) return -1
				if (a.percentAvail < b.percentAvail) return 1
				if (a.percentAvail === b.percentAvail) {
					if (a.totalPrice > b.totalPrice) return 1
					if (a.totalPrice < b.totalPrice) return -1
					return 0
				}
				return 0
			})
	}, [spacesQuery.data, availQuery.data])

	return {
		slotsState,
		dispatchSlots,
		spacesQuery,
		availQuery,
		spacesWithAvail,
	}
}
