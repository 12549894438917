import type * as collection from '@dicebear/collection'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { createZustandSelectors } from '~/lib/create-zustand-selectors'

export const accents = ['slate', 'cocoon', 'indigo', 'fuchsia', 'kaki'] as const

type Accentcolor = (typeof accents)[number]

interface UserPrefsState {
	isSidebarVisible: boolean
	accentColor: Accentcolor
	iconCollection: keyof typeof collection

	setIsSidebarVisible: (isSidebarVisible: boolean) => void
	setAccentColor: (accentColor: Accentcolor) => void
	setIconCollection: (iconCollection: keyof typeof collection) => void
	toggleSidebar: VoidFunction
}

const useUserPrefsStoreBase = create<UserPrefsState>()(
	persist(
		(set, get) => ({
			accentColor: 'slate',
			setAccentColor: (accentColor) => set(() => ({ accentColor })),

			iconCollection: 'bottts',
			setIconCollection: (iconCollection) => set(() => ({ iconCollection })),

			isSidebarVisible: true,
			toggleSidebar: () =>
				set((state) => ({ isSidebarVisible: !state.isSidebarVisible })),
			setIsSidebarVisible: (isSidebarVisible) =>
				set(() => ({ isSidebarVisible })),
		}),
		{
			name: 'user-prefs',
			storage: createJSONStorage(() => window.localStorage),
		},
	),
)

export const useUserPrefsStore = createZustandSelectors(useUserPrefsStoreBase)
