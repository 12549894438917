import { create } from 'zustand'
import { createZustandSelectors } from '~/lib/create-zustand-selectors'

export const accents = ['slate', 'cocoon', 'indigo', 'fuchsia', 'kaki'] as const

type Accentcolor = (typeof accents)[number]

interface UserPrefsState {
	initThemeAndMode: () => void

	accentColor: Accentcolor
	setAccentColor: (accentColor: Accentcolor) => void

	isSidebarVisible: boolean
	toggleSidebar: VoidFunction
	setIsSidebarVisible: (isSidebarVisible: boolean) => void
}

const useUserPrefsStoreBase = create<UserPrefsState>()((set, get) => ({
	initThemeAndMode: () => {
		if (!document) return

		const accentColor = window.localStorage.getItem(
			'accentColor',
		) as Accentcolor

		get().setAccentColor(accentColor)
	},

	accentColor: 'slate',
	setAccentColor: (accentColor) =>
		set(() => {
			if (!document) return { accentColor: 'slate' }

			window.localStorage.setItem('accentColor', accentColor)

			for (const className of document.body.classList) {
				if (className.includes('accent-')) {
					document.body.classList.remove(className)
				}
			}

			if (accentColor) {
				document.body.classList.add(`accent-${accentColor}`)
			}

			return { accentColor }
		}),

	isSidebarVisible: true,
	toggleSidebar: () =>
		set((state) => ({ isSidebarVisible: !state.isSidebarVisible })),
	setIsSidebarVisible: (isSidebarVisible) => set(() => ({ isSidebarVisible })),
}))

export const useUserPrefsStore = createZustandSelectors(useUserPrefsStoreBase)
