import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import localeData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import tz from 'dayjs/plugin/timezone'
import toObject from 'dayjs/plugin/toObject'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import { dayjsUtils } from './plugin'

dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(relativeTime)
dayjs.extend(toObject)
dayjs.extend(tz)
dayjs.extend(utc)
dayjs.extend(weekday)
dayjs.extend(dayjsUtils)
dayjs.extend(localeData)

/**
 * Cf Dayjs documentation for usage
 * @see {@link https://day.js.org/docs/}
 *
 * @see Internal plugin: [DayjsUtils]{@link: ./plugin.ts}
 * */
export const dateTimeUtils = dayjs

export type DateTimeUtils = ReturnType<typeof dayjs>
