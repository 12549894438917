export const cleanQueryKeyFactories = <T extends Record<string, any>>(
	factories: T,
): T => {
	const entries = Object.entries(factories).map(([key, factory]) => [
		key,
		typeof factory === 'function'
			? (...args: any[]) => factory(...args).filter((k: any) => k)
			: factory,
	])

	return Object.fromEntries(entries)
}
