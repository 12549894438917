import * as Sentry from '@sentry/nextjs'
import { getAuth } from 'firebase/auth'
import { useMemo } from 'react'
import { createSdk } from '../../../sdk-js-legacy'

type Sdk = ReturnType<typeof createSdk>
export type NamespacesKeys = keyof Sdk

export const useCocoonApi = <T extends NamespacesKeys>(
	namespace: T,
): Sdk[T] => {
	return useMemo(() => {
		const sdk = createSdk({
			defaultAxiosOptions: {
				adapter:
					typeof window === 'object'
						? require('axios/lib/adapters/xhr')
						: require('axios/lib/adapters/http'),
			},
			env:
				((process.env.EXPO_PUBLIC_APP_ENV || process.env.NEXT_PUBLIC_APP_ENV) as
					| 'preprod'
					| 'test'
					| 'beta'
					| 'prod') || 'preprod',
			getAuthToken: () => {
				if (typeof window === 'object') {
					return getAuth().currentUser?.getIdToken() || Promise.resolve('')
				}

				return Promise.resolve('')
			},
			onApiError: (err) => {
				console.info('API ERROR: ', err) // eslint-disable-line

				Sentry.setContext('api', {
					requestId: err.response?.headers?.['request-id'],
					message: err.message,
				})

				if (err.response?.status !== 401) {
					Sentry.captureException(err)
				}

				return err
			},
		})

		return sdk[namespace]
	}, [namespace])
}
