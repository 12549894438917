import type { Describe } from '@cocoonspace/types/admin/describe'
import type { AxiosInstance } from 'axios'

interface GetModule {
	config: {
		uriParams: {
			describeId: string
		}
	}
	response: {
		data: Describe
	}
}

export const getModuleDescribe =
	(axiosInstance: AxiosInstance) =>
	({ uriParams, ...config }: GetModule['config']) =>
		axiosInstance.get<GetModule['response']>(
			`/_admin/${uriParams.describeId}/describe`,
			config,
		)
